<div class="home-carousel-bg">
    <div class="home-bg fade" [ngClass]="{'op-1': active==1}">
        <div class="img-1 animate-1"><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-1.png')" alt=""></div>
        <div class="img-2 animate-2"><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-1.png')" alt=""></div>
        <div class="img-3 animate-1"><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-1.png')" alt=""></div>
        <div class="img-4 animate-2"><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-1.png')" alt=""></div>
        <div class="img-5 animate-1"><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-1.png')" alt=""></div>
    </div>
    <div class="home-bg fade" [ngClass]="{'op-1': active==2}">
        <div class=""><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-2.png')" alt=""></div>
    </div>

    <div class="home-bg fade" [ngClass]="{'op-1': active==3}">
        <div class=""><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-3.png')" alt=""></div>
    </div>

    <div class="home-bg fade" [ngClass]="{'op-1': active==4}">
        <div class=""><img [src]="globalservice.assetUrl('/assets/img/bg/carousel-4.png')" alt=""></div>
    </div>


    <div class="carousel-nav">
        <div>
            <span [ngClass]="{'active': active==1}" (click)="active=1"></span>
            <span [ngClass]="{'active': active==2}" (click)="active=2"></span>
            <span [ngClass]="{'active': active==3}" (click)="active=3"></span>
            <span [ngClass]="{'active': active==4}" (click)="active=4"></span>
        </div>
    </div>
    <div class="home-brand">
        <img [src]="globalservice.assetUrl('/assets/img/bg/home-brand.png')" alt="">
    </div>


</div>



<div class="office-timezone-container" *ngIf="showtimezone">
    
    <div class="office-timezone">
        <ng-container *ngFor="let o of office; let i = index">
        <div class="office-tz"  [ngClass]="{'active': active_office==i}">
            {{o.name}}
            <br>
            {{timeShow(o)}}
        </div>
        </ng-container>

    </div>
</div>
