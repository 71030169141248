import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';


// import { LoginComponent } from './user/login/login.component';
// import { UserComponent } from './user/user.component';
// import { ResetpasswordComponent } from './user/resetpassword/resetpassword.component';
// import { ChangepassComponent } from './user/changepass/changepass.component';

import { HomeComponent } from './main/home/home.component';
import { MainComponent } from './main/main.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
// import { NestMainComponent } from './main/applications/nest/contract_management/main/nest-main.component';
// import { SeatassignmentMainComponent } from './main/applications/nest/seatassignment/main/seatassignment-main.component';
// import { AssetManagementMainComponent } from './main/applications/nest/asset-management/main/asset_management-main.component';
// import { EmployeeFileMainComponent } from './main/applications/nest/employee-file/main/employee_file-main.component';
// import { FloorPlanMainComponent } from './main/applications/nest/floor-plan/main/floor_plan-main.component';

// import module here do not remove
// import { GenericComponent } from './generic/dataentry/generic_name.component';
import { ChatComponent } from './tools/chat/chat.component';
// import { NestParentComponent } from './main/applications/nest/parent/nestparent.component';
// import { FuelitMainComponent } from './main/applications/fuelit/main/fuelit-main.component';





const routes: Routes = [

  { 
  	path: '', component: MainComponent, 
    canActivate:[AuthGuard],
  	children: [

        {
          path: '', component: HomeComponent
        },  		
        {path: 'generic_main', loadChildren: () => import('./generic/generic.module').then(m => m.GenericModule)},
        {
          path: 'messages', component: ChatComponent
        },  		
        {
          path: 'home', component: HomeComponent
        }, 
        {
          path: '', loadChildren:() => import('./temporary.routing').then(m=> m.TEMPORARY_ROUTES)
        }

  
    ]},

    
    {path: '', loadChildren: () => import('./user/user.module').then(m => m.UserModule)},
   
  { 
  	path: '**', component: PagenotfoundComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
