import { Component, HostListener, OnInit, Inject, Renderer2  } from '@angular/core';
import { DOCUMENT, NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { CommonService } from './services/common/common.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [ToastModule, NgClass, RouterOutlet]
})
export class AppComponent implements OnInit {
  title = 'myapp';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public commonservice: CommonService
  ){

  }

  ngOnInit(): void {


    // let debug:any = localStorage.getItem('debug');
    // // console.log(debug)
    // if(!debug){
    //   console.log = function(){
    //     return 'THIS FUNCTION IS NOT AVAILABLE!';
    //   }
    // }

    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event? : any) {
    let w = window.innerWidth;

    if(w<=700){
      this.commonservice.is_mobile = true;
      this.renderer.addClass(this.document.body, 'is_mobile');
    }else{
      this.commonservice.is_mobile = false;
      this.renderer.removeClass(this.document.body, 'is_mobile');
    }

  }


}




