<div class="mb-3 w-100 pull-left" >
    <ng-container *ngFor="let modules of processed_data; let i = index">
        <ng-container *ngIf="modules.show">
        <div class="mb-3 w-100 pull-left" >
            
            <label 
                class="module-label"> 
                {{ modules.head.name }}
            </label>
            
           
                <ng-container *ngFor="let sub_module of modules.data">
                    <div class="app-box-container">
                        <div class="app-module-inner" (click)="changeLink(sub_module.link, sub_module.app)">
                            <ng-container *ngIf="sub_module.icon">
                                <img  [src]="globalservice.assetUrl('/assets/img/ic/'+sub_module.icon)" alt="">
                                <br>
                            </ng-container>
                                <span>
                                    {{ sub_module.name }}
                                </span>
                        </div>
                    </div>
                </ng-container>


    
        </div>
        </ng-container>
    
    </ng-container>
    
</div>
