import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomCookieService } from '../services/custom-cookie/custome-cookie.service';
import { ConfigService } from '../services/app-config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private router: Router, private cookieService: CustomCookieService, private configService: ConfigService){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      const token = this.cookieService.get('token');
      if(token!=null && token!=undefined && token!=""){
        this.configService.setUsrId();
        if(localStorage.getItem('last_url') && localStorage.getItem('last_url')!= ''){
          let last_url:any = localStorage.getItem('last_url');
          localStorage.setItem('last_url','');

          if(last_url.includes('login') || last_url.includes('null')){
            this.router.navigate(['']);
          }else{
            this.router.navigate([last_url]);
          }
        }
        return true;
    }else{
      if((window.location.pathname).includes('login') || (window.location.pathname).includes('null')){
        localStorage.setItem('last_url','');
      }else{
        localStorage.setItem('last_url',window.location.pathname);
      }
     
      this.router.navigate(['/login']);
      return false;
    }

  }
  
}
