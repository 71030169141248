import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { ListboxModule } from 'primeng/listbox';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SidebarModule } from 'primeng/sidebar';
import { DividerModule } from 'primeng/divider';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { SharedModule } from './app/main/shared/shared.module';
import { CommonModule } from '@angular/common';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { CustomCookieService } from './app/services/custom-cookie/custome-cookie.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CompanyService } from './app/services/general/company.service';
import { GlobalService } from './app/services/global/global.service';

import { ModuleService } from './app/services/general/module.service';
import { ProjectService } from './app/services/general/project.service';
import { UserService } from './app/services/user/user.service';
import { ShipService } from './app/services/general/ship.service';
import { SocketService } from './app/services/socket/socket.service';
import { CommonService } from './app/services/common/common.service';
import { ConfigService } from './app/services/app-config/config.service';
import { SystemEventService } from './app/services/system-event/system-event.service';
import { ProxyService } from './app/services/proxy/proxy.service';
import { JsonConfigService } from './app/services/json-config/json-config.service';
import { FullCalendarModule } from '@fullcalendar/angular';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, CommonModule, SharedModule, AppRoutingModule, DialogModule,
          DynamicDialogModule, ToastModule, OverlayPanelModule, TieredMenuModule, DividerModule, SidebarModule, 
        // JitCompilerModule,
          ConfirmDialogModule, BadgeModule, TabViewModule, ListboxModule, FieldsetModule, PanelModule,
          AvatarModule, AvatarGroupModule, SkeletonModule, MessagesModule, MessageModule, SelectButtonModule,
          ToggleButtonModule, ProgressSpinnerModule, BreadcrumbModule, ProgressBarModule, FullCalendarModule),
        CommonService,
        SocketService,
        ShipService,
        UserService,
        ProjectService,
        ModuleService,
        ConfigService,
        GlobalService,
        CompanyService,
        MessageService,
        ConfirmationService,
        CustomCookieService,
        SystemEventService,
        ProxyService,
        JsonConfigService,
        provideAnimations()
    ]
})
  .catch(err => console.error(err));
